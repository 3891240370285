import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch('https://fidooflexi.upi-group.cz/application/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password })
      });

      if (!response.ok) {
        console.error("Login failed");
        return;
      }

      const data = await response.json();
      const token = data?.session?.token;
      if (token) {
        sessionStorage.setItem('sessionToken', token);
        navigate('/dashboard');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
      <div
          className="d-flex justify-content-center align-items-center vh-100"
          style={{ background: '#f8f9fa' }}
      >
        <div className="card p-4" style={{ width: '400px' }}>
          <h1 className="mb-4 text-center">Přihlášení</h1>
          <form onSubmit={handleLogin}>
            <div className="form-group mb-3">
              <label htmlFor="username">Uživatelské jméno:</label>
              <input
                  type="text"
                  id="username"
                  className="form-control"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  placeholder="Zadejte uživatelské jméno"
                  required
              />
            </div>
            <div className="form-group mb-4">
              <label htmlFor="password">Heslo:</label>
              <input
                  type="password"
                  id="password"
                  className="form-control"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  placeholder="Zadejte heslo"
                  required
              />
            </div>
            <button type="submit" className="btn btn-primary w-100">Přihlásit se</button>
          </form>
        </div>
      </div>
  );
};

export default Login;
