import React, {JSX} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';

function App() {
  return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
        />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
  );
}

function ProtectedRoute({ children }: { children: JSX.Element }) {
  const token = sessionStorage.getItem('sessionToken');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
}

export default App;